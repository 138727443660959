import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL + '/widgets';

// Crear un nuevo widget
export async function createWidget(widgetData, token) {
  try {
    const response = await axios.post(API_BASE_URL, widgetData, {
      headers: { Authorization: "Bearer " + token }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Obtener todos los widgets
export async function getAllWidgets(token) {
  try {
    const response = await axios.get(API_BASE_URL + '/all', {
      headers: { Authorization: "Bearer " + token }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Obtener un widget por ID
export async function getWidgetById(widgetId, token) {
  try {
    const response = await axios.get(`${API_BASE_URL}/${widgetId}`, {
      headers: { Authorization: "Bearer " + token }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Actualizar un widget por ID
export async function updateWidget(widgetId, widgetData, token) {
  try {
    const response = await axios.put(`${API_BASE_URL}/${widgetId}`, widgetData, {
      headers: { Authorization: "Bearer " + token }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Eliminar un widget por ID
export async function deleteWidget(widgetId, token) {
  try {
    const response = await axios.delete(`${API_BASE_URL}/${widgetId}`, {
      headers: { Authorization: "Bearer " + token }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Obtener widgets por ID de dashboard
export async function getWidgetsByDashboardId(dashboardId, token) {
  try {
    const response = await axios.get(`${API_BASE_URL}/dashboard/${dashboardId}`, {
      headers: { Authorization: "Bearer " + token }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}