import React, { useState, useEffect } from 'react';
import DatePicker from './Datepicker';
import ButtonForm from './ButtonForm';

const DateRangePicker = ({ onChange, closeWindow, options={} }) => {
  const [activeTab, setActiveTab] = useState(options.activeTab  || 'customDates');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedRange, setSelectedRange] = useState(options.activeTab === 'customDates' ? options.selectedRange || 'today' : '');
  const [customLast, setCustomLast] = useState(options.customLast || 1);
  const [customUnit, setCustomUnit] = useState(options.customUnit || 'minutes');
  console.log(options, endDate, startDate)
 useEffect( () => {
  if (options.endDate){
    setEndDate(new Date(options.endDate))
  }
  if (options.startDate){
    setStartDate(new Date(options.startDate))
  }
  
  console.log(options, endDate, startDate)
 },[options])

  // Funciones de cálculo de fechas para rangos rápidos
  const handleQuickRangeSelection = (range) => {
    const now = new Date();
    let start, end;
    console.log(range)
    switch (range) {
      case 'lastHour':
        start = new Date(now.getTime() - 60 * 60 * 1000);
        end = now;
        break;
      case 'today':
        start = new Date(now.setHours(0, 0, 0, 0));
        end = now;
        break;
      case 'yesterday':
        start = new Date(now.setDate(now.getDate() - 1));
        start.setHours(0, 0, 0, 0);
        end = new Date(now.setHours(23, 59, 59, 999));
        break;
      case 'last24Hours':
        start = new Date(now.getTime() - 24 * 60 * 60 * 1000);
        end = now;
        break;
      case 'thisWeek':
        start = new Date(now.setDate(now.getDate() - now.getDay() + 1));
        start.setHours(0, 0, 0, 0);
        end = now;
        break;
      case 'lastWeek':
        const prevWeekStart = new Date(now.setDate(now.getDate() - now.getDay() - 6));
        start = new Date(prevWeekStart.setHours(0, 0, 0, 0));
        end = new Date(now.setDate(now.getDate() - now.getDay()));
        end.setHours(23, 59, 59, 999);
        break;
      case 'thisMonth':
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = now;
        break;
      case 'lastMonth':
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end = new Date(now.getFullYear(), now.getMonth(), 0);
        end.setHours(23, 59, 59, 999);
        break;
      case 'last7Days':
        start = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        end = now;
        break;
      case 'last30Days':
        start = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
        end = now;
        break;
      case 'customized':
        start = startDate
        end = endDate
        break;
      default:
        start = new Date();
        end = new Date();
    }

    setSelectedRange(range);
    setStartDate(start);
    setEndDate(end);
  };

  // Manejar el cambio en los inputs de fechas en la opción "Personalizado"
  const handleDateInputChange = (e) => {
    setSelectedRange("customized");
    const { name, value } = e.target;
    if (name === 'start') setStartDate(value);
    if (name === 'end') setEndDate(value);
  };

  // Manejar el cambio en el input numérico y selector de la opción personalizada
  const handleCustomRangeChange = () => {
    let now = new Date();
    let newStartDate; // Cambiado a una nueva variable
    let newEndDate = now; // Inicializa endDate a ahora

    switch (customUnit) {
      case 'minutes':
        newStartDate = new Date(now.getTime() - customLast * 60 * 1000);
        break;
      case 'hours':
        newStartDate = new Date(now.getTime() - customLast * 60 * 60 * 1000);
        break;
      case 'days':
        newStartDate = new Date(now.getTime() - customLast * 24 * 60 * 60 * 1000);
        break;
      case 'months':
        newStartDate = new Date(now.setMonth(now.getMonth() - customLast));
        break;
      default:
        newStartDate = new Date();
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate); // Asegúrate de usar setEndDate
  };

  useEffect(() => {
    handleQuickRangeSelection(selectedRange);
  }, [selectedRange]);

  const handleAccept = () => {
    // Si estamos en "últimos valores", aplicamos el rango seleccionado
    if (activeTab === 'customRange') {
      handleCustomRangeChange();
    }
    // Ejecutar onChange con las fechas actuales
    if (onChange) {
      let toEmit = {activeTab, startDate, endDate}
      if (activeTab === 'customDates'){
        toEmit.selectedRange = selectedRange
      }
      if (activeTab === "customRange"){
        toEmit.customUnit = customUnit
        toEmit.customLast = Number(customLast)
      }
      onChange(toEmit);
    }
  };

  const handleCancel = () => {
    // Ejecutar closeWindow cuando se cancela
    if (closeWindow) {
      closeWindow();
    }
  };

  return (
    <div className="bg-white flex border rounded-lg shadow-md w-full">
      {/* Left Section: Quick Ranges */}
      <div className="w-1/2 border-r p-2">
        <h3 className="text-center p-2 bg-bluesens text-white">Rangos rápidos</h3>
        <ul className="text-sm list-none my-1 p-0">
          {['lastHour', 'today', 'yesterday', 'last24Hours', 'thisWeek', 'lastWeek', 'last7Days', 'thisMonth', 'lastMonth', 'last30Days'].map((range) => (
            <li
              key={range}
              className={`cursor-pointer p-2 ${selectedRange === range ? 'bg-gray-400 text-white' : 'hover:bg-gray-200'}`}
              onClick={() => { handleQuickRangeSelection(range); setActiveTab("customDates");}}
            >
              {range === 'lastHour' ? 'Última Hora' :
                range === 'today' ? 'Hoy' :
                range === 'yesterday' ? 'Ayer' :
                range === 'last24Hours' ? 'Últimas 24 Horas' :
                range === 'thisWeek' ? 'Esta Semana' :
                range === 'lastWeek' ? 'Semana Pasada' :
                range === 'last7Days' ? 'Últimos 7 Días' :
                range === 'thisMonth' ? 'Este Mes' :
                range === 'lastMonth' ? 'Mes Pasado' :
                'Últimos 30 Días'}
            </li>
          ))}
        </ul>
      </div>

      {/* Right Section: Custom Range */}
      <div className="w-1/2 p-2">
        <h3 className="text-center p-2 bg-bluesens text-white">Personalizado</h3>
        <div className="flex justify-between">
          <button
            className={`w-1/2 p-2 text-gray-400 ${activeTab === 'customDates' ? 'border-b-2 border-y-bluesens' : ''}`}
            onClick={() => setActiveTab('customDates')}
          >
            Rango de fechas
          </button>
          <button
            className={`w-1/2 p-2 text-gray-400 ${activeTab === 'customRange' ? 'border-b-2 border-y-bluesens' : ''}`}
            onClick={() => setActiveTab('customRange')}
          >
            Últimos valores
          </button>
        </div>

        {activeTab === 'customDates' ? (
          <div className="p-2">
            <div className='w-full -mb-2'>
              <DatePicker
                text={"Fecha inicio"}
                value={startDate}
                onDateChange={(date) => handleDateInputChange({ target: { name: "start", value: date } })}
              />
            </div>
            <div className="w-full">
              <DatePicker
                text={"Fecha fin"}
                value={endDate}
                onDateChange={(date) => handleDateInputChange({ target: { name: "end", value: date } })}
              />
            </div>
          </div>
        ) : (
          <div className="p-2">
            <div className="flex items-center">
              <label htmlFor="customLast" className="mr-2 text-sm">Últimos</label>
              <input
                type="number"
                name="customLast"
                value={customLast}
                onChange={(e) => setCustomLast(e.target.value)}
                className="border p-2 rounded-md w-1/3"
              />
              <select
                value={customUnit}
                onChange={(e) => setCustomUnit(e.target.value)}
                className="border p-2 rounded-md ml-2"
              >
                <option value="minutes">Minutos</option>
                <option value="hours">Horas</option>
                <option value="days">Días</option>
                <option value="months">Meses</option>
              </select>
            </div>
          </div>
        )}

        {/* Botones Aceptar y Cancelar */}
        <div className='flex flex-col'>
          <div className="flex justify-around p-4">
            <ButtonForm 
              action={handleAccept}
              text={"Aceptar"}
              enabled={true}
            />
            <button
              className="px-4 py-2 bg-gray-400 text-white rounded"
              onClick={handleCancel}
            >
              Cancelar
            </button>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default DateRangePicker;