import React, { createContext, useState, useContext, useRef } from "react";
import { useAuth } from "./AuthContext";
import {
  createWidget,
  deleteWidget,
  updateWidget,
} from "../services/apiWidgests";
const WidgetContext = createContext();
export const useWidgets = () => useContext(WidgetContext);

export const WidgetProvider = ({ children }) => {
  const { auth } = useAuth();
  const containerRef = useRef(null);

  const [cards, setCards] = useState([]);

  let dashboardList = [
    {
      name: "REPORTE DE OPERACIONES Y VENTAS",
      id: "AB6263232323123123213",
    },
    {
      name: "REPORTE DE EXTRUSION",
      id: "6FA26B3232323123123213",
    },
    {
      name: "REPORTE DE SELLADO EXOTERMICO",
      id: "9FC26B3232323123123213",
    },
  ];

  const [dashboard, setDashboard] = useState(dashboardList[0]);

  const isOverlapping = (card1, card2) => {
    return (
      card1.x < card2.x + card2.width &&
      card1.x + card1.width > card2.x &&
      card1.y < card2.y + card2.height &&
      card1.y + card1.height > card2.y
    );
  };

  const resolveCollision = (movingCard, otherCards) => {
    let newX = movingCard.x;
    let newY = movingCard.y;
    let spaceFound = false;

    while (!spaceFound) {
      spaceFound = true;

      for (let card of otherCards) {
        if (isOverlapping({ ...movingCard, x: newX, y: newY }, card)) {
          spaceFound = false;
          newX += 220; // Move to the right
          if (newX + movingCard.width > containerRef.current.offsetWidth) {
            newX = 0;
            newY += movingCard.height + 20; // Move to the next row
          }
          break;
        }
      }
    }
    return { newX, newY };
  };

  const handleMove = async (id, newX, newY, newWidth, newHeight) => {
    console.log("handleMove - WidgetProvider");
    // const test = await updateWidget(id, { x: newX, y: newY, width: newWidth, height: newHeight }, auth?.token);
    setCards((prevCards) => {
      let movingCard = prevCards.find((card) => card.id === id);
      let updatedCards = prevCards.map((card) => {
        if (card.id === id) {
          const adjustedX = newX !== undefined ? Math.max(newX, 0) : card.x;
          const adjustedY = newY !== undefined ? Math.max(newY, 0) : card.y;

          return {
            ...card,
            x: adjustedX,
            y: adjustedY,
            width: newWidth !== undefined ? newWidth : card.width,
            height: newHeight !== undefined ? newHeight : card.height,
          };
        }
        return card;
      });

      updatedCards.forEach((card) => {
        if (card.id !== id && isOverlapping(movingCard, card)) {
          const { newX: nextX, newY: nextY } = resolveCollision(
            card,
            updatedCards.filter((c) => c.id !== card.id)
          );
          card.x = nextX;
          card.y = nextY;
        }
      });

      return updatedCards;
    });
  };

  const handleDrag = (id, newX, newY) => {
    handleMove(id, newX, newY);
  };

  const handleResize = (id, newWidth, newHeight) => {
    handleMove(id, undefined, undefined, newWidth, newHeight);
  };

  const addCard = async (
    cardId,
    title,
    type,
    dashboardId,
    x,
    y,
    width,
    height
  ) => {
    cardId = cardId ?? Date.now();
    title = title ?? `Widget ${Math.random().toString(36).substr(2, 5)}`;
    dashboardId = dashboardId ?? "defaultDashboard";

    setCards((prevCards) => {
      const lastCard = prevCards[prevCards.length - 1];
      let newCardX = x ?? (lastCard ? lastCard.x + 220 : 0);
      let newCardY = y ?? (lastCard ? lastCard.y : 0);
      let cardWidth = width ?? 200;
      let cardHeight = height ?? 200;

      if (x == null || y == null) {
        let spaceFound = false;
        while (!spaceFound) {
          spaceFound = true;
          for (let card of prevCards) {
            if (
              isOverlapping(
                {
                  x: newCardX,
                  y: newCardY,
                  width: cardWidth,
                  height: cardHeight,
                },
                card
              )
            ) {
              spaceFound = false;
              newCardX += 220;
              if (newCardX + cardWidth > containerRef.current.offsetWidth) {
                newCardX = 0;
                newCardY += 220;
              }
              break;
            }
          }
        }
      }

      return [
        ...prevCards,
        {
          id: cardId, // Usa el ID proporcionado o el predeterminado
          x: newCardX,
          y: newCardY,
          width: cardWidth,
          height: cardHeight,
          title: title, // Usa el título proporcionado o el predeterminado
          type: type, // Usa el tipo proporcionado o el predeterminado
          dashboardId: dashboardId, // Usa el dashboardId proporcionado o el predeterminado
        },
      ];
    });
  };

  const createCard = async (type, dashboardId) => {
    console.log("createCard - WidgetProvider");
    const resCreateWitget = await createWidget(
      {
        type: type,
        title: "Sales Chart2",
        description: "A chart showing sales data",
        configuration: {
          color: "blue",
          size: "large",
        },
        dashboardId: dashboardId,
        organizationId: "66967f427805c57a5ea8cae8",
        userId: "6667370a97454af1883eab8e",
        x: 28,
        y: 63,
        width: 200,
        height: 200,
      },
      auth?.token
    );
    console.log(resCreateWitget);
    setCards((prevCards) => {
      const lastCard = prevCards[prevCards.length - 1];
      let newCardX = lastCard ? lastCard.x + 220 : 0;
      let newCardY = lastCard ? lastCard.y : 0;

      let spaceFound = false;
      while (!spaceFound) {
        spaceFound = true;
        for (let card of prevCards) {
          if (
            isOverlapping(
              { x: newCardX, y: newCardY, width: 200, height: 200 },
              card
            )
          ) {
            spaceFound = false;
            newCardX += 220;
            if (newCardX + 200 > containerRef.current.offsetWidth) {
              newCardX = 0;
              newCardY += 220;
            }
            break;
          }
        }
      }

      return [
        ...prevCards,
        {
          id: prevCards.length + 1,
          x: newCardX,
          y: newCardY,
          width: 200,
          height: 200,
          title: `Widget ${prevCards.length + 1}`,
          type: type,
        },
      ];
    });
  };

  const duplicateCard = (id) => {
    setCards((prevCards) => {
      const cardToDuplicate = prevCards.find((card) => card.id === id);
      if (!cardToDuplicate) return prevCards;

      let newCardX = cardToDuplicate.x + 220;
      let newCardY = cardToDuplicate.y;

      let spaceFound = false;
      while (!spaceFound) {
        spaceFound = true;
        for (let card of prevCards) {
          if (
            isOverlapping(
              {
                x: newCardX,
                y: newCardY,
                width: cardToDuplicate.width,
                height: cardToDuplicate.height,
              },
              card
            )
          ) {
            spaceFound = false;
            newCardX += 220;
            if (
              newCardX + cardToDuplicate.width >
              containerRef.current.offsetWidth
            ) {
              newCardX = 0;
              newCardY += 220;
            }
            break;
          }
        }
      }

      return [
        ...prevCards,
        {
          id: prevCards.length + 1,
          x: newCardX,
          y: newCardY,
          width: cardToDuplicate.width,
          height: cardToDuplicate.height,
          title: `Widget ${prevCards.length + 1}`,
          type: cardToDuplicate.type,
        },
      ];
    });
  };

  const deleteCard = async (id) => {
    console.log("deleteCard - WidgetProvider", id);
    const resDeleteWidget = await deleteWidget(id, auth?.token);
    // FIXME: CREAR VALIDADORES DE RESPUESTA
    setCards((prevCards) => prevCards.filter((card) => card.id !== id));
  };

  const deleteAllCard = () => {
    setCards([]);
  };

  const updateCard = async (id, x, y, width, height) => {
    console.log("updateCard - WidgetProvider", id);
    const resCreateWitget = await updateWidget(
      id,
      {
        x: x,
        y: y,
        width: width,
        height: height,
      },
      auth?.token
    );
  };

  return (
    <WidgetContext.Provider
      value={{
        containerRef,
        cards,
        setCards,
        addCard,
        handleDrag,
        handleResize,
        duplicateCard,
        deleteCard,
        createCard,
        deleteAllCard,
        updateCard,
        dashboard,
        setDashboard,
        dashboardList,
      }}
    >
      {children}
    </WidgetContext.Provider>
  );
};
