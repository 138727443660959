import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../../providers/AuthContext.js";
import { useAppContext } from '../../../providers/AppContext.js'
import SyncIco from '../../../components/icons/SyncIco.jsx'
import MenuIcon from '../../../components/icons/MenuIcon.jsx'
import Spinner from "../../../components/Spinner";
import Modal from "../../../components/Modal";
import Container from '../../../layouts/Container'
import TextInput from "../../../components/TextInput";
import SelectInput from "../../../components/SelectInput";
import InputForm from '../../../components/InputForm.jsx'

import { getVariableById, getVariableByName, updateVariable, deleteVariable } from "../../../services/apiVariables";
import { getMeasuresByVariable, getMeasureTable, deleteMeasure } from "../../../services/apiMeasures.js";
import GenericTable from '../../../components/GenericTable'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import html2canvas from 'html2canvas';

function VariableDetails() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { variableId } = useParams();
  const {addToast} = useAppContext();
  
  // **Estado**
  const [variable, setVariable] = useState(null);
  const [name, setName] = useState("");
  const [alias, setAlias] = useState("");
  const [unity, setUnity] = useState("");
  const [type, setType] = useState("");
  const [measures, setMeasures] = useState([]);
  const [startDate, setStartDate] = useState(new Date(Date.now() - 24 * 60 * 60 * 1000));
  const [endDate, setEndDate] = useState(new Date());
  const [errorMessageName, setErrorMessageName] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [parameterToDelete, setParameterToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCapturing, setIsCapturing] = useState(false);

  // **Referencias**
  const tableRef = useRef();
  const menuRef = useRef(null);
  const captureRef = useRef(null);

  const typeOptions = ["General", "GPS"];

  // **Funcion para Manejo de Errores**
  const handleApiError = (error) => {
    console.error('API error:', error);
    if (error.response?.status === 404) {
      addToast("Proceso no encontrado", 3);          
    } else if (error.response?.status === 419) {
      addToast("Sesión expirada", 1);
      navigate('/logout');
    } else if (error.response?.status === 500) {
      addToast("Error en el servidor, intente nuevamente", 3);
    } else {
      navigate('/logout'); 
    }
  };

 // **Funciones de Fecha**
 function formatDate(dateStr) {
  const date = new Date(dateStr);

  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

function formatDateRange(startDate, endDate) {
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };

  const start = startDate ? startDate.toLocaleString('es-Es', options) : ''
  const end = endDate ? endDate.toLocaleString('es-Es', options) : ''
  return `${start} - ${end}`
}

// **Funciones de API**

// Funcion fetcher para GenericTbale
const fetcher = async (token, currentPage, documentsPerPage, sortBy) => {
  try {
    const data = await getMeasureTable(
      token,
      currentPage,
      documentsPerPage,
      JSON.stringify(sortBy),
      startDate,
      endDate,
      variableId,
    );
    return data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

// fetch para obtener los datos de una variable
const fetchVariable = async (variableId) => {
  try {
    const variable = await getVariableById(variableId, auth?.token);
    setVariable(variable);
    setName(variable?.name);
    setAlias(variable?.alias);
    setType(variable?.type);
    setUnity(variable?.unity);
  } catch (error) {
    handleApiError(error);
  }
};

// fetch para measure que se colocaran en recharts
const fetchMeasures = async () => {
  if (variableId && startDate && endDate) {
    try {
      const data = await getMeasuresByVariable(variableId, startDate, endDate, auth?.token);
      const transformedData = data.map((item) => ({
        date: item.dtm,
        value: item.value,
      }));
      setMeasures(transformedData);
    } catch (error) {
      console.error("Error al obtener las medidas:", error);
    }
  }
};
// fetch usado en la generacion del archivo .csv
const fetchAllData = async () => {
  try {
    const data = await getMeasureTable(
      auth?.token,
      1,
      0,
      JSON.stringify({ column: "dtm", isAscending: true }),
      startDate,
      endDate,
      variableId,
    );
    return data.records;
  } catch (error) {
    console.error('Error al obtener todos los datos:', error);
  }
};

// **Efectos**
useEffect(() => {
  fetchVariable(variableId);
}, [variableId, auth?.token]);

// Efecto para API de medidas en recharts usando la funcion fetchMeasures()
useEffect(() => {
  fetchMeasures();
}, [variableId, startDate, endDate, auth?.token]);

useEffect(() => {
  if (tableRef.current) {
    tableRef.current.refreshTable();
  }
}, [startDate, endDate]);

useEffect(() => {
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };
  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);

useEffect(() => {
  const validateName = async () => {
    if (name) {
      try {
        const found_variable = await getVariableByName(name, auth?.token);
        const exist = typeof found_variable !== "undefined";
        if (!exist || name.toLowerCase() === variable?.name.toLowerCase()) {
          setErrorMessageName("");
        } else {
          addToast("Este nombre de variable ya está en uso", 1);
        }
      } catch (error) {
        handleApiError(error);
      }
    } else {
      setErrorMessageName("");
    }
  };
  validateName();
}, [name, variable, auth?.token]);

useEffect(() => {
  setIsFormValid(name !== "" && !errorMessageName);
}, [name, errorMessageName]);

useEffect(() => {
  async function validateName() {
    if (name) {
      getVariableByName(name, auth?.token).then(found_variable => {
        const exist = typeof found_variable !== "undefined";
        if (!exist || name.toLowerCase() === variable?.name.toLowerCase())
          setErrorMessageName("");
        else {
          addToast("Este nombre de variable ya esta en uso", 1);
        }
      }).catch(error =>
        navigate('/logout')
      );
      
    } else {
      setErrorMessageName("");
    }
  }
  validateName();
}, [name, variable]);

useEffect(() => {
  setIsFormValid(name !== "" && !errorMessageName);
}, [name, errorMessageName]);

// **Funciones de Eventos**
const handleDateRangeChange = ({ startDate, endDate }) => {
  setStartDate(startDate);
  setEndDate(endDate);
};

const resetDatesToToday = () => {
  if (tableRef.current) {
    tableRef.current.refreshTable();
  }
  fetchMeasures();
};

const handleMenuToggle = () => {
  setIsMenuOpen(!isMenuOpen);
};

const handleDownloadCSV = async () => {
  const data = await fetchAllData();
  if (data) {
    const csvContent = convertToCSV(data);
    const filename = generateFilename();

    if ('showSaveFilePicker' in window) {
      // El navegador soporta la File System Access API
      try {
        const opts = {
          suggestedName: filename,
          types: [
            {
              description: 'Archivos CSV',
              accept: { 'text/csv': ['.csv'] },
            },
          ],
        };
        const handle = await window.showSaveFilePicker(opts);
        const writable = await handle.createWritable();
        await writable.write(new Blob([csvContent], { type: 'text/csv;charset=utf-8;' }));
        await writable.close();
      } catch (error) {
        console.error('Error al guardar el archivo:', error);
        addToast('Error al guardar el archivo. Por favor, inténtalo de nuevo.', 3);
      }
    } else {
      // Método de respaldo para navegadores que no soportan la API
      downloadCSV(csvContent, filename);
    }
  }
  setIsMenuOpen(false);
};

const handleDownloadPNG = async () => {
  if (captureRef.current) {
    setIsCapturing(true);
    try {
      // Esperar un pequeño tiempo para que todo esté renderizado
      await new Promise((resolve) => setTimeout(resolve, 300));

      const canvas = await html2canvas(captureRef.current, { scale: 2 });
      const image = canvas.toDataURL('image/png');

      const link = document.createElement('a');
      link.href = image;
      link.download = 'captura.png';
      link.click();
    } catch (error) {
      console.error('Error al capturar la pantalla:', error);
    }
    setIsCapturing(false);
  }
};

const handleBackClick = () => {
  navigate(-1);
};

const handleDeleteClick = async () => {
  try {
    await deleteVariable(variable, auth?.token);
    addToast("Variable eliminada exitosamente", 0);
    setTimeout(() => {
      navigate(-1);
    }, 1000);
  } catch (error) {
    handleApiError(error);
  }
};

const handleOpenDeleteModal = (measure) => {
  setParameterToDelete(measure);
  setIsDeleteModalOpen(true);
};

const handleCloseDeleteModal = () => {
  setIsDeleteModalOpen(false);
  setParameterToDelete(null);
};

const handleDeleteMeasure = async () => {
  try {
    await deleteMeasure(parameterToDelete, auth?.token);
    addToast('Medida eliminada correctamente', 0);
    handleCloseDeleteModal();
    if (tableRef.current) {
      tableRef.current.refreshTable();
    }
  } catch (error) {
    handleApiError(error);
  }
};

const handleSubmit = async (event) => {
  event.preventDefault();
  const info = { name, type, alias, unity };
  try {
    await updateVariable({ ...variable, ...info }, auth?.token);
    addToast("Variable editada exitosamente!", 0);
  } catch (error) {
    handleApiError(error);
  }
};

// **Funciones de Utilidad**

// Funcion para establecer el nombre del archivo csv con su respectivo formato fecha-hora-nombrevariable
function generateFilename() {
  let variableName = name || 'variable';
  variableName = variableName.replace(/[\/\\?%*:|"<>]+/g, '').replace(/\s+/g, '_');

  const startDateObj = startDate instanceof Date ? startDate : new Date(startDate);
  const endDateObj = endDate instanceof Date ? endDate : new Date(endDate);

  if (isNaN(startDateObj.getTime()) || isNaN(endDateObj.getTime())) {
    console.error('Las fechas proporcionadas no son válidas.');
    return `${variableName}.csv`;
  }

  function formatDateTime(date) {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    return `${day}-${month}-${year}-${hours}_${minutes}`;
  }

  const formattedStartDate = formatDateTime(startDateObj);
  const formattedEndDate = formatDateTime(endDateObj);

  const filename = `${formattedStartDate}_${formattedEndDate}_${variableName}.csv`;
  return filename;
}


const convertToCSV = (data) => {
  if (!data || data.length === 0) return '';

  const headers = Object.keys(data[0]).filter(header => header !== 'ACCIONES' && header !== '_id' && header !== 'variableId');
  const csvRows = [];

  csvRows.push(headers.join(';'));

  for (const row of data) {
    const values = headers.map(header => {
      const val = row[header];
      const escaped = ('' + val).replace(/"/g, '""');
      return `"${escaped}"`;
    });
    csvRows.push(values.join(';'));
  }

  return csvRows.join('\n');
};

const downloadCSV = (csvContent, fileName) => {
  const bom = '\uFEFF';
  const blob = new Blob([bom + csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');

  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

// **Componentes Personalizados**
const CustomXAxisTick = ({ x, y, payload }) => {

  const date = new Date(payload.value);
  const year = date.getUTCFullYear().toString().slice(-2);
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
        {`${day}/${month}/${year}`}
      </text>
      <text x={0} y={20} dy={16} textAnchor="middle" fill="#666">
        {`${hours}:${minutes}`}
      </text>
    </g>
  );
};

const actions = {
  DELETE: handleOpenDeleteModal,
};

if (!variable) {
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <Spinner />
    </div>
  );
}

  return (
    <Container>
      <div className="px-1 py-4 flex flex-col mt-1 bg-graysens">
        <div className="flex justify-between">
          <button
            onClick={handleBackClick}
            className="btn btn-secondary flex items-center transform hover:-translate-x-2 transition duration-300"
          >
            <img
              src={`${process.env.PUBLIC_URL}/svg/arrow_back.svg`}
              alt="Botón de Volver"
              className="h-5 w-5"
            />
            Regresar
          </button>
          <button
            onClick={handleDeleteClick}
            className="btn btn-secondary flex items-center hover:scale-75"
            title="Eliminar Variable"
          >
            <img
              src={`${process.env.PUBLIC_URL}/svg/deleteIco.svg`}
              alt=" Botón de Eliminar Variable"
              className="h-5 w-5"
            />
          </button>
        </div>

        <div className="px-1 py-4  flex items-start h-5/6">
          <div className="flex flex-col items-center mb-4 w-1/3 h-max gap-4 bg-white rounded-md shadow-custom p-4 mr-4">
            <h2 className="text-base font-semibold text-center mt-8">Información de la Variable</h2>
            <TextInput
              label="Nombre"
              id="variable_name"
              value={name}
              onChange={(e) => setName(e.target.value.trim().replace(/\s/g, "_"))}
              error={errorMessageName}
            />
            <TextInput
              label="Alias"
              id="variable_alias"
              value={alias}
              onChange={(e) => setAlias(e.target.value.trim().replace(/\s/g, "_"))}
            />
            <TextInput
              label="Última actividad"
              id="variable_lastActivity"
              value={formatDate(variable.lastActivity)}
              enabled={false}
              AppendClassName="bg-gray-100 cursor-not-allowed"
            />
            <TextInput
              label="Unidad"
              id="variable_unity"
              value={unity}
              onChange={(e) => setUnity(e.target.value.trim().replace(/\s/g, "_"))}
            />
            <SelectInput
              label="Tipo"
              id="device_type"
              options={typeOptions.slice(1)}
              value={type}
              onChange={(e) => setType(e.target.value)}
              placeholder={typeOptions[0]}
            />
            <div className="flex justify-center">
              <button
                type="submit"
                id="submit-btn-role-form"
                className={`bg-bluesens w-48 text-white mt-10 mb-24 font-bold py-2 px-4 rounded disabled:opacity-50 disabled:pointer-events-none`}
                disabled={!isFormValid}
                onClick={handleSubmit}
              >
                <span className="">Guardar</span>
              </button>
            </div>
          </div>

          <div className="w-full flex flex-col gap-4">
            {/* Datepicker */}
            <div className="w-full p-4 bg-white rounded-md shadow-custom z-10">
              <InputForm
                type="datarange"
                text="Rango de fechas"
                value={formatDateRange(startDate, endDate)}
                AppendClassName="text-left -mb-4"
                onChange={handleDateRangeChange}
              />
            </div>
            
            <div>
              {/* Rechart */}
              <div className="w-full relative" >
                <div className=" p-4 bg-bluesens rounded-t-md flex justify-between items-center mt-0">
                  <h2 className="text-sm text-white font-semibold">
                    Tendencia de mediciones
                  </h2>
                  <button onClick={resetDatesToToday} className="text-white focus:outline-none m-0">
                    <SyncIco className="w-6 h-6"/>
                  </button>
                </div>
              </div>

              <div className="w-full p-4 bg-white rounded-b-md shadow-custom -mt-2">
                <div className="w-full">
                  <div className="flex justify-between items-center mb-2 ">
                    <h3 className="text-left font-normal ">{`${name} vs Tiempo`}
                    </h3>
                    <button onClick={handleMenuToggle} className="text-black focus:outline-none">
                      <MenuIcon className="w-6 h-6"/>
                    </button>
                  </div>

                  {isMenuOpen && (
                    <div ref={menuRef} className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-50">
                    <ul className="py-1">
                      <li>
                        <button
                          onClick={handleDownloadCSV}
                          className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          Descargar CSV
                        </button>
                      </li>
                        <li>
                          <button
                            onClick={handleDownloadPNG}
                            className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            disabled={isCapturing}
                          >
                            {isCapturing ? 'Capturando...' : 'Descargar Imagen'}
                          </button>
                        </li>
                    </ul>
                  </div>
                  )}
                  <div ref={captureRef} style={{ backgroundColor: 'white', padding: '10px' }}>
                    {measures.length === 0 && (
                        <div className='flex items-center justify-center text-gray-700 my-5'>
                          <span>No se encontraron registros</span>
                        </div>
                    )}
                    {measures.length > 0 && (
                      <ResponsiveContainer width="100%" height={340} onLoad={() => console.log('Cargado')}>
                      <AreaChart
                          width={500}
                          height={340}
                          data={measures}
                          margin={{ top: 15, right: 30, left: 20, bottom: 50 }}
                          >
                          <CartesianGrid horizontal={true} vertical={false} />
                          <XAxis 
                            dataKey="date"
                            tick={<CustomXAxisTick />}
                            textAnchor="end"
                            interval={4}
                            label={{
                              value: "Tiempo",
                              position: "insideBottom",
                              offset: -45,
                              style: { textAnchor: 'middle', fill: '#666', fontWeight: 'bold' }
                            }}
                          />
                          <YAxis
                            label={{
                              value: name, 
                              angle: -90,  
                              position: "insideLeft",
                              dx: -20,
                              style: { textAnchor: 'middle', fill: '#666', fontWeight: 'bold' }
                            }}
                            tickFormatter={(tick) => tick.toFixed(2)}
                          />
                          <Tooltip />
                          <Area
                            type="monotone"
                            dataKey="value"
                            stroke="#8884d8"
                            fill="#8884d8"
                            fillOpacity={0.3}
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    )}
                  </div>
                </div>
              </div>
            </div>
            

            {/* Tabla */}
            <div className="w-full  bg-white rounded-lg shadow-custom ">
              <GenericTable
                ref={tableRef}
                actions={actions}
                fetcher={fetcher}
                externalId={null}
              />
            </div>
          </div>

        </div>
      </div>

      {/* Modal para confirmar la eliminación de la organización */}
      {isDeleteModalOpen && parameterToDelete && (
        <Modal
          isOpen={isDeleteModalOpen}
          onClose={handleDeleteMeasure}
          title="Eliminar Organización"
          showCloseButton={true}
          size="w-1/3"
          colorTitle="bg-red-500"
        >
          <div className="p-4">
            <p className="mb-4">
              ¿Estás seguro de que deseas eliminar la medida de este dispositivo{' '}
              <strong>{parameterToDelete.Nombre}</strong>?
            </p>
            <div className="flex justify-end">
              <button
                onClick={handleCloseDeleteModal}
                className="mr-2 px-4 py-2 bg-gray-500 text-white rounded-md"
              >
                Cancelar
              </button>
              <button
                onClick={handleDeleteMeasure}
                className="px-4 py-2 bg-red-600 text-white rounded-md"
              >
                Eliminar
              </button>
            </div>
          </div>
        </Modal>
      )}

    </Container>
  );
}

export default VariableDetails;
