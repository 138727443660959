import React from "react";
import DeviceRow from "./DeviceRow";

function DeviceTable({ devices, onEdit, onDelete, onSort, onAssign, sortBy }) {
  sortBy = JSON.parse(sortBy);
  const handleHeaderClick = (column) => {
    if (onSort) {
      onSort(column);
    }
  };

  return (
    <table className="w-full table-auto">
      <thead>
        <tr>
          <th
            onClick={() => handleHeaderClick("name")}
            className={`cursor-pointer px-4 py-2 text-left w-1/6`}
          >
            Nombre{" "}
            {(sortBy.column === "name" && (sortBy.isAscending ? "↑" : "↓")) ||
              "↑↓"}
          </th>
          <th
            onClick={() => handleHeaderClick("alias")}
            className={`cursor-pointer px-4 py-2 text-left w-1/6`}
          >
            Alias{" "}
            {(sortBy.column === "alias" &&
              (sortBy.isAscending ? "↑" : "↓")) ||
              "↑↓"}
          </th>
          <th
            onClick={() => handleHeaderClick("lastVersionUpdateAt")}
            className={`cursor-pointer px-4 py-2 text-left`}
          >
            Última Actividad{" "}
            {(sortBy.column === "lastActivity" &&
              (sortBy.isAscending ? "↑" : "↓")) ||
              "↑↓"}
          </th>
          <th
            onClick={() => handleHeaderClick("createdAt")}
            className={`cursor-pointer px-4 py-2 text-left`}
          >
            Fecha de creación{" "}
            {(sortBy.column === "createdAt" &&
              (sortBy.isAscending ? "↑" : "↓")) ||
              "↑↓"}
          </th>
          <th
            onClick={() => handleHeaderClick("organizationIds")}
            className={`cursor-pointer px-4 py-2 text-left`}
          >
            Organizaciones{" "}
            {(sortBy.column === "organizationIds" &&
              (sortBy.isAscending ? "↑" : "↓")) ||
              "↑↓"}
          </th>
          <th className="px-4 py-2 text-left">Acciones</th>
        </tr>
      </thead>
      <tbody>
        {devices?.length > 0 ? (
          devices.map((device) => (
            <DeviceRow
              key={device._id}
              device={device}
              onAssign={onAssign}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          ))
        ) : (
          <tr>
            <td colSpan="6" className="text-center py-4">
              No hay dispositivos existentes
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default DeviceTable;
