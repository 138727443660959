import React, { useState, useEffect } from "react";
import { getOrganizationById } from "../../../services/apiOrganizations";
import { useNavigate } from 'react-router-dom';

import { elapsedTime } from '../../utils.js';
import { useAuth } from "../../../providers/AuthContext.js";

function DeviceRow({ device, onEdit, onDelete, onAssign }) {
  const { auth } = useAuth();
  const [organizationName, setOrganizationName] = useState('');

  const navigate = useNavigate();

  const handleRowClick = () => {
    navigate(`/dispositivo/${device._id}`); 
  };

  useEffect(() => {
    async function fetchOrganizationName() {
      if (device.organizationId) {
        try {
          getOrganizationById(device.organizationId, auth?.token).then( organization => {
            setOrganizationName(organization.name);
          }).catch( error =>
            navigate('/logout')
          );
          
        } catch (error) {
          console.error("Error fetching organization names:", error);
        }
      }
    }
    fetchOrganizationName();
  }, [device.organizationId]);

  const [elapsedTimeString, setElapsedTimeString] = useState(
    elapsedTime(device.lastActivity || device.createdAt)
  );
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      const newElapsedTimeString = elapsedTime(device.lastActivity || device.createdAt);
      if (newElapsedTimeString.includes("minuto") || newElapsedTimeString.includes("segundo")) {
        setElapsedTimeString(newElapsedTimeString);
      }
    }, 60000);
  
    return () => clearInterval(intervalId);
  }, [device.lastActivity || device.createdAt]); 

  return (
    <tr className="border-b hover:bg-gray-100">
      <td onClick={handleRowClick} className="px-4 py-2 cursor-pointer">{device.name}</td>
      <td onClick={handleRowClick} className="px-4 py-2 cursor-pointer">{device.alias!=='Unnamed' ? device.alias : "-"}</td>
      <td className="px-4 py-2">Hace {elapsedTimeString}</td>
      <td className="px-4 py-2">
        {new Date(device.createdAt)
          .toISOString()
          .replace("T", " ")
          .slice(0, -5)}
      </td>
      <td className="px-4 py-2">{organizationName || "-"}</td>
      <td className="px-4 py-2">
        <button onClick={() => onAssign(device)} className="mr-2">
          <img
            src={`${process.env.PUBLIC_URL}/svg/organizationIco.svg`}
            alt="Asignar org"
            className="h-5 w-5 hover:scale-150 transition-transform"
          />
        </button>
        <button onClick={() => onEdit(device)} className="mr-2">
          <img
            src={`${process.env.PUBLIC_URL}/svg/editIco.svg`}
            alt="Editar"
            className="h-5 w-5 hover:scale-150 transition-transform"
          />
        </button>
        <button onClick={() => onDelete(device)}>
          <img
            src={`${process.env.PUBLIC_URL}/svg/deleteIco.svg`}
            alt="Eliminar"
            className="h-5 w-5 hover:scale-150 transition-transform"
          />
        </button>
      </td>
    </tr>
  );
}

export default DeviceRow;
