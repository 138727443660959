import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Modal from "../../../components/Modal";
import TextInput from "../../../components/TextInput";
import SelectInput from "../../../components/SelectInput";
import Container from '../../../layouts/Container'

import {
  getDeviceById,
  getDeviceByName,
  updateDevice,
} from "../../../services/apiDevices";

import {
  getVariablesByDevice,
  createVariable,
} from "../../../services/apiVariables";
import Spinner from "../../../components/Spinner";

import VariableCard from "../../variables/components/VariableCard.jsx";

import { formatDate } from "../../utils.js";
import { useAuth } from "../../../providers/AuthContext.js";
import FunctionsIco from "../../../components/icons/FunctionsIco";
import AddIco from "../../../components/icons/AddIco";

function CardsSkeleton () {
  return (
    <div className="grid grid-cols-6 gap-2 mt-2 p-8 bg-white">
      { [1,2,3,4,5,6].map( number => 
      
        <div
        className={
          "p-4 flex flex-col justify-center items-center bg-gray-100 animate-pulse rounded-xl h-48 w-full text-center" 
        }>
          <div className="basis-1/2 h-full w-full justify-center items-center flex flex-col">
            
          </div>
          <div className="basis-1/2 h-full w-full flex flex-col">
          
          </div>
        </div>
      ) }
    </div>
  )
}
function DeviceDetails() {
  const {auth} = useAuth();
  const navigate = useNavigate();

  const { deviceId } = useParams();
  const [device, setDevice] = useState(null);
  const [name, setName] = useState("");
  const [alias, setAlias] = useState("");
  const [type, setType] = useState("");
  const [variables, setVariables] = useState([]);
  const typeOptions = ["Default", "GPS"];

  const [errorMessageName, setErrorMessageName] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [loadingVars, setLoadingVars] = useState(false);


  const refreshVariables = useCallback(async () => {
    try {
      setLoadingVars(true);
      getVariablesByDevice(deviceId, auth?.token).then(variablesData => {
        setLoadingVars(false);
        setVariables(variablesData);
      }).catch(error => {
        setLoadingVars(false);
        navigate('/logout')
      });
      
    } catch (error) {
      setLoadingVars(false);
      console.error("Error fetching devices:", error);
    }
  }, [deviceId]);

  useEffect(() => {
    refreshVariables();
  }, [refreshVariables]);

  useEffect(() => {
    setIsFormValid(name !== "" && !errorMessageName);
  }, [name, errorMessageName]);

  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    async function fetchDevice(deviceId) {
      getDeviceById(deviceId, auth?.token).then(deviceData => {
        setDevice(deviceData);
        setName(deviceData?.name);
        setAlias(deviceData?.alias);
        setType(deviceData?.type);
      }).catch(error =>
        navigate('/logout')
      );
    }
    fetchDevice(deviceId);
  }, [deviceId]);

  useEffect(() => {
    async function validateName() {
      if (name) {
        getDeviceByName(name, auth?.token).then(found_device => {
          const exist = typeof found_device !== "undefined";
          if (!exist || name.toLowerCase() === device?.name.toLowerCase())
            setErrorMessageName("");
          else {
            setErrorMessageName("Este nombre de dispositivo ya esta en uso");
          }
        }).catch(error => 
          navigate('/logout')
        );
      } else {
        setErrorMessageName("");
      }
    }
    validateName();
  }, [name, device]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let info = { name, type, alias };
    try {
      await updateDevice({ ...device, ...info }, auth?.token).then(response => {
        setSuccessMessage("Dispositivo editado exitosamente!");
        setShowSuccessModal(true);
      }).catch( error => 
        navigate('/logout')
      );
    } catch (error) {
      setErrorMessage(
        "Error al editar dispositivo. Por favor, inténtalo de nuevo."
      );
      setShowErrorModal(true);
    }
  };

  useEffect(() => {
    if (showSuccessModal) {
      const timer = setTimeout(() => {
        setShowSuccessModal(false);
        setSuccessMessage("");
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [showSuccessModal]);

  useEffect(() => {
    if (showErrorModal) {
      const timer = setTimeout(() => {
        setShowErrorModal(false);
        setErrorMessage("");
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [showErrorModal]);

  

  const handleCreateVariable = async () => {
    try {
      const lastVariable = variables && variables.at(-1);
      let newVariableName = "Nueva_Variable1";
      if (lastVariable && lastVariable.name.includes("Nueva_Variable")) {
        newVariableName =
          "Nueva_Variable" + (parseInt(lastVariable.name.slice(-1)) + 1);
      }
      createVariable({ deviceId, name: newVariableName }, auth?.token).then(response => {
        setSuccessMessage("Variable creada exitosamente!");
        setShowSuccessModal(true);
        refreshVariables();
      }).catch(error => 
        navigate('/logout')
      );
      
    } catch (error) {
      setErrorMessage(
        "Error al crear variable. Por favor, inténtalo de nuevo."
      );
      setShowErrorModal(true);
    }
  };

  return (
    <Container>
      <div className="px-1 py-4 flex flex-col mt-1 bg-graysens">
        <div>
          <button
            onClick={handleBackClick}
            className="btn btn-secondary flex items-center transform hover:-translate-x-2 transition duration-300"
          >
            <img
              src={`${process.env.PUBLIC_URL}/svg/arrow_back.svg`}
              alt="Previous Page"
              className="h-5 w-5"
            />
            Regresar
          </button>
        </div>
        <div className="px-1 py-4 flex items-start h-screen">
          <div className="flex flex-col items-center mb-4 w-1/4 h-5/6 gap-4 bg-white rounded-md shadow-custom p-4 mr-4">
            <h2 className="text-base font-semibold text-center mt-8">Información del Dispositivo</h2>
            {device && (
              <>
                <TextInput  
                  label="Nombre"
                  id="device_name"
                  value={name}
                  onChange={(e) => setName(e.target.value.trim().replace(/\s/g, ""))}
                  error={errorMessageName}
                />
                <TextInput
                  label="Alias"
                  id="device_alias"
                  value={alias}
                  onChange={(e) => setAlias(e.target.value)}
                />
                <TextInput
                  label="Última actividad"
                  id="device_lastActivity"
                  value={formatDate(device.lastVersionUpdateAt)}
                  enabled={false}
                  AppendClassName="bg-gray-100 cursor-not-allowed"
                />
                <SelectInput
                  label="Tipo"
                  id="device_type"
                  options={typeOptions.slice(1)}
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  placeholder={typeOptions[0]}
                />
                <div className="flex justify-center">
                  <button
                    type="submit"
                    id="submit-btn-role-form"
                    className={`bg-bluesens w-48 text-white mt-10 font-bold py-2 px-4 rounded disabled:opacity-50 disabled:pointer-events-none`}
                    disabled={!isFormValid}
                    onClick={handleSubmit}
                  >
                    <span className="">Guardar</span>
                  </button>
                </div>
              </>
            )}
            
          </div>
          <div className="flex flex-col w-3/4 p-4 h-auto bg-white rounded-md shadow-custom">
          <span className="text-base font-normal text-center mt-8">Añadir variables al dispositivo</span>
          <hr className="border-1 shadow-sm"/>
            {loadingVars && (
              <CardsSkeleton />
            )}
            { !loadingVars && (
              <div className="grid grid-cols-6 gap-2 mt-2 p-8 bg-white ">
                <div
                  className={
                    "p-4 flex flex-col justify-center items-center border-dashed border-2 border-bluesens rounded-xl h-48 w-full text-center" +
                    " cursor-pointer transform transition-transform hover:scale-105"
                  }
                  onClick={handleCreateVariable}
                >
                  <div className="basis-1/2 h-full w-full justify-center items-center flex flex-col">
                    <AddIco 
                      width="48px"
                      height="48px"
                      fill="bluesens"
                    />

                  </div>
                  <div className="basis-1/2 h-full w-full flex flex-col">
                  
                  <p className="text-base font-normal ">Añadir Variable</p>
                  </div>
                  
                </div>
                <div className={
                  "p-4 flex flex-col justify-center items-center border-dashed border-2 border-bluesens rounded-xl h-48 w-full" +
                  " cursor-pointer transform transition-transform hover:scale-105 text-center"
                }>
                  <div className="basis-1/2 h-full w-full justify-center items-center flex flex-col">
                    <FunctionsIco 
                      width="48px"
                      height="48px"
                      fill="bluesens"
                    />
                  </div>
                  <div className="basis-1/2 h-full w-full  flex flex-col">
                    
                    <p className="text-base font-normal mb-2">
                      Añadir Variable Sintética
                    </p>
                  </div>
                
                </div>
                {variables.length > 0 &&
                  variables.map((variable) => (
                    <VariableCard key={variable._id} variable={variable} appendClass={"h-48 w-full" +
                    " cursor-pointer transform transition-transform hover:scale-105"} />
                ))}
                
                
              </div> 
            )}
             
          </div>
        </div>
        
      </div>
    </Container>
  );
}

export default DeviceDetails;
