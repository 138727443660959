import React, { useState } from "react";

import TableChartIco from "../../../../components/icons/TableChartIco";
import HorizontalBarIco from "../../../../components/icons/HorizontalBarIco";
import PieChartIco from "../../../../components/icons/PieChartIco";
import { useWidgets } from "../../../../providers/WidgetContext";
import { useDashboards } from "../../../../providers/DashboardContext";
import HorizontalBarChartForm from "../widgets/HorizontalBarChart/HorizontalBarChartForm";
import RegistersTableForm from "../widgets/RegistersTable/RegistersTableForm";
import PieChartForm from "../widgets/PieChart/PieChartForm";
import Modal from "../../../../components/Modal";
import { createWidget } from "../../../../services/apiWidgests";
import { useAuth } from "../../../../providers/AuthContext";

const widgets = [
  { name: "Tabla de valores", icon: TableChartIco, type: "processTable" },
  {
    name: "Gráfico de barras",
    icon: HorizontalBarIco,
    type: "processHorizontalBarChart",
  },
  { name: "Gráfico Pastel", icon: PieChartIco, type: "processPieChart" },
];

const WidgetCard = ({
  widget,
  toggleModal,
  setShowWidgetForm,
  setTitleForm,
  setTypeForm,
}) => {
  const { dashboard } = useDashboards();
  const { addCard, createCard } = useWidgets();

  const handleAdd = async (dashboardId) => {
    createCard(widget.type, dashboardId);
    setShowWidgetForm(true);
    setTitleForm(widget.name);
    setTypeForm(widget.type);
    /* if (widget.type === "processTable"){
      setContentForm(RegistersTableForm)
    }
    else{
      setShowWidgetForm(false);
      toggleModal();
      addCard(widget.type)
    } */
  };
  return (
    <>
      <div
        className="flex flex-col border rounded-md w-32 h-32 shadow-md transform transition-transform duration-300 hover:scale-105 cursor-pointer"
        onClick={() => handleAdd(dashboard._id)}
      >
        <div className="flex basis-2/3 items-center justify-center">
          <widget.icon fill={"darkgray"} height={"50px"} width={"50px"} />
        </div>
        <div className="basis-1/3 flex items-center justify-center border-t">
          {widget.name}
        </div>
      </div>
    </>
  );
};

function NewWidget({ toggleNewWidgetModal}) {
  const [showWidgetForm, setShowWidgetForm] = useState(false);
  const [titleForm, setTitleForm] = useState("");
  const [typeForm, setTypeForm] = useState("");

  return (
    <div className="flex flex-wrap w-full h-96 gap-3 ">
      <Modal
        isOpen={showWidgetForm}
        onClose={() => {
          setShowWidgetForm(false);
        }}
        title={titleForm}
        customType={2}
        colorTitle="bg-bluesens"
        size="w-2/4"
      >
        {typeForm === "processTable" && <RegistersTableForm />}
        {typeForm === "processHorizontalBarChart" && <HorizontalBarChartForm />}
        {typeForm === "processPieChart" && <PieChartForm />}
      </Modal>
      {widgets.map((widget, index) => (
        <WidgetCard
          key={index}
          widget={widget}
          toggleModal={toggleNewWidgetModal}
          setShowWidgetForm={setShowWidgetForm}
          setTitleForm={setTitleForm}
          setTypeForm={setTypeForm}
        />
      ))}
    </div>
  );
}

export default NewWidget;
