import React, { useState, useEffect, useRef } from "react";
// Asegúrate de importar correctamente el icono de menú desde tu librería o archivo local
import MenuIcon from "../../../../components/icons/MenuIcon";
import SearchIco from "../../../../components/icons/SearchIco";
import InputForm from "../../../../components/InputForm";
import ButtonForm from "../../../../components/ButtonForm";
import TrashIco from "../../../../components/icons/TrashIco";
import EditIco from "../../../../components/icons/EditIco";
import SkipPreviousIco from "../../../../components/icons/SkipPreviousIco";
import SkipNextIco from "../../../../components/icons/SkipNextIco";
import ArrowBack from "../../../../components/icons/ArrowBack";
import ArrowForward from "../../../../components/icons/ArrowForward";

import ComboBox from "../../../../components/ComboBox";
import Modal from "../../../../components/Modal";

import DashboardForm from "./DashboardForm";
import { useDashboards } from "../../../../providers/DashboardContext";
import {
  deleteDashboard,
  getDashboards,
} from "../../../../services/apiDashboards";
import { getWidgetsByDashboardId } from "../../../../services/apiWidgests";
import { useAuth } from "../../../../providers/AuthContext";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../../providers/AppContext";
import Spinner from "../../../../components/Spinner";
import { useWidgets } from "../../../../providers/WidgetContext";

function Pagination({ total, currentPage, maxPaginationPages, onChange }) {
  const totalPages = Math.ceil(total / maxPaginationPages);

  function getPagination(c, m, g) {
    // Si el número máximo de números a mostrar es mayor o igual al total de páginas, mostramos todas las páginas
    if (g >= m) {
      return Array.from({ length: m }, (_, i) => i + 1);
    }
    let start, end;
    // Si la página actual está en el principio
    if (c <= Math.floor(g / 2)) {
      start = 1;
      end = g;
    }
    // Si la página actual está cerca del final
    else if (c + Math.floor(g / 2) >= m) {
      start = m - g + 1;
      end = m;
    }
    // Si la página actual está en el medio
    else {
      start = c - Math.floor(g / 2);
      end = c + Math.floor(g / 2);
    }
    // Generamos el arreglo de números de página
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  }

  let arrayPages = getPagination(currentPage, totalPages, maxPaginationPages);

  return (
    <div className="flex gap-1">
      <button
        className={`mx-0.5 rounded-md min-w-6 h-6 bg-white ${
          currentPage !== 1 ? "hover:bg-graysens" : "cursor-not-allowed"
        }`}
        onClick={(event) => onChange(1)}
      >
        <SkipPreviousIco fill={currentPage !== 1 ? "gray" : undefined} />
      </button>
      <button
        className={`mx-0.5 rounded-md min-w-6 h-6 bg-white ${
          currentPage !== 1 ? "hover:bg-graysens" : "cursor-not-allowed"
        }`}
        onClick={(event) =>
          onChange(currentPage - 1 <= 1 ? 1 : currentPage - 1)
        }
      >
        <ArrowBack
          height="16px"
          width="16px"
          fill={currentPage !== 1 ? "gray" : undefined}
        />
      </button>
      {arrayPages.map((element, index) => (
        <button
          key={index}
          className={`mx-0.5 rounded-md min-w-6 h-6 ${
            element === currentPage
              ? "bg-bluesens text-white"
              : "bg-white hover:bg-graysens"
          }`}
          value={element}
          onClick={(event) => onChange(event.target.value)}
        >
          {element}
        </button>
      ))}

      <button
        className={`mx-0.5 rounded-md min-w-6 h-6 bg-white ${
          currentPage !== totalPages
            ? "hover:bg-graysens"
            : "cursor-not-allowed"
        }`}
        onClick={(event) =>
          onChange(currentPage + 1 >= totalPages ? totalPages : currentPage + 1)
        }
      >
        <ArrowForward
          height="16px"
          width="16px"
          fill={currentPage !== totalPages ? "gray" : undefined}
        />
      </button>
      <button
        className={`mx-0.5 rounded-md min-w-6 h-6 bg-white ${
          currentPage !== totalPages
            ? "hover:bg-graysens"
            : "cursor-not-allowed"
        }`}
        onClick={(event) => onChange(totalPages)}
      >
        <SkipNextIco fill={currentPage !== totalPages ? "gray" : undefined} />
      </button>
    </div>
  );
}

const DashboardMenu = () => {
  const { addCard, deleteAllCard } = useWidgets();
  const { setDashboard } = useDashboards();
  const { auth } = useAuth();
  const { addToast } = useAppContext();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsNumber, setRowsNumber] = useState(5);
  const [total, setTotal] = useState(0);
  const [newDashboardModal, setNewDashboardModal] = useState(false);
  const [showDeleteDashboard, setShowDeleteDashboard] = useState(false);
  const [isBoxOpen, setIsBoxOpen] = useState(false);
  const [dashboardToEdit, setDashboardToEdit] = useState();
  const menuRef = useRef(null);

  //banderas backend
  const [fetchingData, setFetchingData] = useState(true);
  const [deletingDashboard, setDeletingDashboard] = useState(false);
  const [dashboardList, setDashboardList] = useState();
  const toggleBox = () => {
    setIsBoxOpen(!isBoxOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsBoxOpen(false);
    }
  };

  const refreshTable = () => {
    async function fetchData() {
      //page = 1, limit = 5, searchTerm = "", sortBy = JSON.stringify({ column: "createdAt", isAscending: true })
      // FIXME: EJEMPLO DE TOKEN
      let dashboard = await getDashboards(
        auth?.token,
        "process",
        currentPage,
        rowsNumber,
        search
      );

      return dashboard;
    }
    try {
      setFetchingData(true);
      fetchData()
        .then((response) => {
          console.log(console.log(response));
          setDashboardList(response.records);
          setTotal(response.totalRecords);
          setFetchingData(false);
        })
        .catch((error) => {
          if (!error.response) {
            addToast("Error inesperado, intente nuevamente", 2);
          } else if (error.response.status === 419) {
            addToast("Sesión expirada", 1);
            navigate("/logout");
          } else {
            addToast("Error en el servidor, intente nuevamente", 2);
          }
        });
    } catch (error) {
      addToast("Error inesperado, intente nuevamente", 2);
    }
  };

  useEffect(() => {
    if (isBoxOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isBoxOpen]);

  useEffect(() => {
    if (isBoxOpen) {
      refreshTable();
    }
  }, [isBoxOpen, rowsNumber, currentPage, search]);

  const handlePagination = (selectedPage) => {
    setCurrentPage(Number(selectedPage));
  };

  const handleRowSelection = (event) => {
    setRowsNumber(Number(event.currentTarget.value));
    setCurrentPage(1);
  };

  const handleDashboardSelection = async (event, selectedDashboard) => {
    deleteAllCard();
    if (event.target.nodeName === "SPAN" || event.target.nodeName === "DIV") {
      setDashboard(selectedDashboard);
      const resDasboard = await getWidgetsByDashboardId(
        selectedDashboard._id,
        auth?.token
      );
      resDasboard.forEach((widget) => {
        addCard(
          widget._id,
          widget.title,
          widget.type,
          widget.dashboardId,
          widget.x,
          widget.y,
          widget.width,
          widget.height
        );
      });
      toggleBox();
    }
  };

  const handleDeleteDashboard = () => {
    async function doDelete() {
      const deleted = await deleteDashboard(
        { _id: dashboardToEdit._id },
        auth?.token
      );
      return deleted;
    }
    try {
      setDeletingDashboard(true);
      doDelete()
        .then((response) => {
          setShowDeleteDashboard(false);
          setDeletingDashboard(false);
          refreshTable();
          addToast("Tablero eliminado", 0);
        })
        .catch((error) => {
          console.log(error, typeof error);
          addToast("Ocurrió un error al eliminar el tablero ", 3);
          setDeletingDashboard(false);
        });
    } catch (error) {
      console.log(error);
      addToast("Ocurrió un error inesperado, intente nuevamente", 3);
      setDeletingDashboard(false);
    }
  };

  const toggleNewDashboardModal = (event) => {
    setNewDashboardModal((prev) => !prev);
  };

  const toggleDeleteDashboardModal = (event) => {
    setShowDeleteDashboard((prev) => !prev);
  };
  return (
    <div className="relative inline-block" ref={menuRef}>
      {/* Modal de edicion */}
      <Modal
        isOpen={newDashboardModal}
        onClose={() => {
          setDashboardToEdit(undefined);
          setNewDashboardModal(false);
        }}
        title={"Información del tablero"}
        customType={2}
        colorTitle="bg-bluesens"
        size="w-2/4"
      >
        <DashboardForm
          dashboard={dashboardToEdit}
          toggle={toggleNewDashboardModal}
          refreshList={refreshTable}
        />
      </Modal>
      {/* Modal confirmación eliminar registro*/}
      <Modal
        isOpen={showDeleteDashboard}
        onClose={() => {
          setShowDeleteDashboard(false);
          setDashboardToEdit(null);
        }}
        customType={2}
        colorTitle="bg-red-600 "
        title={"Eliminar tablero: " + dashboardToEdit?.name}
      >
        <p className="mb-4">¿Está seguro de eliminar este tablero?</p>
        <div className="flex justify-end">
          <button
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2"
            onClick={() => handleDeleteDashboard()}
            disabled={deletingDashboard}
          >
            {deletingDashboard ? "Eliminando..." : "Eliminar"}
          </button>
          <button
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
            onClick={() => {
              setShowDeleteDashboard(false);
              setDashboardToEdit(null);
            }}
          >
            Cancelar
          </button>
        </div>
      </Modal>
      {/* Botón que contiene el ícono y controla la visibilidad del recuadro */}
      <button
        onClick={toggleBox}
        className="p-2 rounded-md hover:bg-gray-200 focus:outline-none"
      >
        <MenuIcon />
      </button>
      {/* Recuadro que se muestra/oculta según el estado */}
      {isBoxOpen && (
        <div className="absolute left-0 mt-2 w-[40rem] max-h-[35rem] p-4 bg-white shadow-lg border rounded-md z-20">
          <div className="flex flex-col h-full">
            <div className="w-[80%]">
              <InputForm
                text={"Buscar"}
                Icon={() => <SearchIco />}
                onChange={(event) => setSearch(event.target.value)}
                enabled={!fetchingData}
              />
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-400">Tableros</span>
              <ButtonForm
                text={"Nuevo"}
                enabled={!fetchingData}
                action={() => {
                  setDashboardToEdit(undefined);
                  toggleNewDashboardModal();
                }}
              />
            </div>
            <div className="flex flex-col justify-between w-full h-full py-2">
              {fetchingData && <Spinner />}
              {!fetchingData && total !== 0 && (
                <>
                  <section className="flex flex-col  overflow-y-auto max-h-[20rem]">
                    {dashboardList.map((dashboard) => (
                      <div
                        className="cursor-pointer flex justify-between py-2 hover:bg-gray-100"
                        key={dashboard.name}
                        onClick={(event) =>
                          handleDashboardSelection(event, dashboard)
                        }
                      >
                        <div className="basis-3/4 text-wrap">
                          <span className="max-w-full text-gray-600">
                            {dashboard.name}
                          </span>
                        </div>
                        <div className="flex gap-3 pr-3">
                          <button
                            onClick={() => {
                              setDashboardToEdit(dashboard);
                              toggleNewDashboardModal();
                            }}
                          >
                            <EditIco fill="bluesens" />
                          </button>
                          <button
                            onClick={() => {
                              setDashboardToEdit(dashboard);
                              toggleDeleteDashboardModal();
                            }}
                          >
                            <TrashIco fill="red" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </section>

                  {/* Footer */}
                  <section
                    id="footer"
                    className="w-100 mt-2  items-center flex "
                  >
                    <div className="flex justify-between py-3 w-full">
                      <div className="mx-3 text-sm  text-gray-500">
                        {total} total
                      </div>
                      <div className="mx-9">
                        <ComboBox
                          value={rowsNumber}
                          label={"resultados por página"}
                          options={[5, 10, 15, 20, 25, 30]}
                          onChange={handleRowSelection}
                        />
                      </div>
                      <div className="mx-3">
                        <Pagination
                          total={total}
                          currentPage={currentPage}
                          maxPaginationPages={rowsNumber}
                          onChange={handlePagination}
                        />
                      </div>
                    </div>
                  </section>
                </>
              )}
              {!fetchingData && total === 0 && (
                <div className="flex w-full justify-center">
                  <span>No se muestran datos por el momento</span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardMenu;
