import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL + '/measures'; // URL de variables


export async function getMeasuresByVariable(variableId, startDate, endDate, token) {
    console.log("Variable ID:", variableId);
    console.log("Start Date:", startDate);
    console.log("End Date:", endDate);
    try {
      const response = await axios.get(`${API_BASE_URL}/byVariable/${variableId}`, {
        params: {
          startDate,
          endDate,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error al obtener las medidas:', error.response || error);
      throw error;
    }
  }

  export async function getMeasureTable(
    token,
    page = 1,
    limit = 10,
    sortBy = JSON.stringify({ column: "dtm", isAscending: true }),
    startDate,
    endDate,
    variableId
  ) {
    console.log("getMeasureTable - startDate:", startDate);
    console.log("getMeasureTable - endDate:", endDate);
    try {
      const response = await axios.get(API_BASE_URL + '/measures/table', {
        params: {
          currentPage: page,
          documentsPerPage: limit,
          sortBy,
          startDate: startDate ? startDate.toISOString() : undefined,
          endDate: endDate ? endDate.toISOString() : undefined,
          variableId
        },
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error('Error getting measures: ' + error.message);
    }
  }

// Delete an organization
export async function deleteMeasure(deleteMeasure, token) {
  try {
    const response = await axios.delete(`${API_BASE_URL}/${deleteMeasure._id}`,
      {
        headers: {Authorization: "Bearer "+token}
      }
    );
    return response.data;
  } catch (error) {
    throw new Error('Error deleting measure: ' + error.message);
  }
}