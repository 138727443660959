import React, { useEffect, useState } from 'react'
import InputForm from '../../../components/InputForm'
import ButtonForm from '../../../components/ButtonForm'
import AddIco from '../../../components/icons/AddIco'
import TrashIco from '../../../components/icons/TrashIco'
import CheckIco from '../../../components/icons/CheckIco'
import DatePicker from '../../../components/Datepicker'
import { useAuth } from '../../../providers/AuthContext'
import { useAppContext } from '../../../providers/AppContext'
import { useNavigate } from 'react-router-dom'
import { useProcesses } from '../../../providers/ProcessesContext'
import { getAllParameters } from '../../../services/apiProcessParameteres'
import Spinner from '../../../components/Spinner'
import SpinnerText from '../../../components/SpinnerText'
import { createRegister, updateRegister, getRegisterById } from '../../../services/apiProcessRegisters'

const InputList = ({ text, initialItems = [], error = "", onChange }) => {
  const [items, setItems] = useState(initialItems);

  const handleInputChange = (index, field, value) => {
    const updatedItems = [...items];
    updatedItems[index] = { ...updatedItems[index], [field]: value };
    setItems(updatedItems);
    onChange(updatedItems);
  };

  const handleAddItem = () => {
    setItems([...items, { key: '', value: '', units: '' }]);
    onChange([...items, { key: '', value: '', units: '' }]);
  };

  const handleRemoveItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
    onChange(updatedItems);
  };

  return (
    <div className={"relative w-full h-full rounded-md border-2  mb-5" + (error ? " animate-shake border-red_error " : " border-gray-300")}>
      <label
        className={(error ? "text-red_error" : "text-gray-500") + " duration-300 transform -translate-y-6 scale-75 top-4 left-4 origin-[0] bg-white px-1 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:left-4 peer-focus:scale-75 peer-focus:-translate-y-6 peer-focus:font-medium absolute"}
      >
        {text}
      </label>
      <div className="w-full min-h-10 p-3">
        {items.length === 0 ? (
          <div className="flex w-full h-full items-center my-3 mx-auto">
            <span className="my-auto mx-auto">No hay elementos en la lista</span>
          </div>
        ) : (
          items.map((item, index) => (
            <div key={index} className="flex items-center gap-5">
              <InputForm text={"key"} placeholder={"Key"} value={item.key} onChange={(e) => handleInputChange(index, 'key', e.target.value)}/>
              <InputForm text={"Value"} placeholder={"Value"} value={item.value} onChange={(e) => handleInputChange(index, 'value', e.target.value)}/>
              <InputForm text={"Units"} placeholder={"Units"} value={item.units} onChange={(e) => handleInputChange(index, 'units', e.target.value)}/>
              
              <button
                className='-mt-5'
                onClick={() => handleRemoveItem(index)}
              >
                <TrashIco fill='red' />
              </button>
            </div>
          ))
        )}
        <div className="flex w-full justify-center ">
          <ButtonForm
            text="Agregar campo"
            Icon={() => <AddIco fill="white" />}
            enabled={true}
            action={handleAddItem}
          />
        </div>
      </div>
      {error && (
        <div className='relative pt-1'>
          <span className='px-1 absolute text-red_error'>
            {error}
          </span>
        </div>
      )}
    </div>
  );
};

function AddEditRegisters({register , toggleModal, refreshTable}) {
  const {auth} = useAuth();
  const {addToast} = useAppContext();
  const navigate = useNavigate();
  const {selectedOption} = useProcesses();
  const [config, setConfig] = useState([]);
  const [data, setData] = useState({});
  const [formError, setFormError] = useState({});
  const [initialRegister, setInitialRegister] = useState();

  //backend flags
  const [fetchingParameters, setFetchingParameters] = useState(true);
  const [fetchingRegister, setFetchingRegister] = useState(false);
  const [updating, setUpdating] = useState(false);

  const handleModifyForm = (options) => {
    const {field , target, changeTo} = options
    let tempData = {...data}
    tempData[field][target] = changeTo 
    setData(tempData)
  }
  const handleSaveData = () => {
    async function doAdd(){
      let registerToSave;
      if (register){
        console.log(data)
        registerToSave = await updateRegister(
          {
            _id: register._id,
            data: data
          },
        auth?.token);
      }else{
        registerToSave = await createRegister(
          {
            processId: selectedOption,
            data: data
          },
        auth?.token);
      }
      return registerToSave;
    }
    try{
      setFormError({field: "", error: ""})
      setUpdating(true);
      doAdd().then(response => {
        setUpdating(false);
        addToast(register ? 'Registro actualizado' : 'Registro agregado' , 0);
        toggleModal();
        refreshTable();
      }).catch(error => {
        setUpdating(false);
        if ([400].includes(error.response.status)){
          let errorData = error.response.data
          if (errorData && errorData.field && errorData.message){
            let tempError = {...formError}
            tempError.field =  errorData.field
            tempError.error =  errorData.message
            setFormError(tempError);
          }
        }
        if ([404].includes(error.response.status)){
          addToast("Registro no encontrado",2)
        }
        if ([419].includes(error.response.status)){
          addToast("Sesión expirada",1)
          navigate('/logout')
        }
        if ([500].includes(error.response.status)){
          addToast('Ocurrió un error al crear el registro ' , 3);
          toggleModal();
        } 
      })
    }
    catch( error) {
      console.log(error);
      setUpdating(false);
      addToast('Ocurrió un error inesperado, intente nuevamente' , 3)    
    }
  }
  const refreshParameters = () => {
    async function fetchParameters(){
      const parameters = await getAllParameters(selectedOption, auth?.token);
      return parameters;
    }
    try {
      setFetchingParameters(true);
      fetchParameters().then(response => {
        console.log(console.log(response))
        setConfig(response.map(parameter => { 
          return {_id: parameter._id, key: parameter.key, alias: parameter.alias, datatype: parameter.datatype}
        }));
        setFetchingParameters(false);
      }).catch(error => {
        if (error.response.status === 404){
          addToast("Proceso no encontrado",3)
        }
        else if (error.response.status === 500){
          addToast("Error en el servidor, intente nuevamente",2)
        }
        else if (error.response.status === 419){
          addToast("Sesión expirada",1)
          navigate('/logout')
        }
        else{
          navigate('/logout')
        }
      })
    } catch (error) {
      console.log(error, "a", typeof(error));
      setFetchingParameters(false);
    }
  }
  
  useEffect( () => {
    console.log(register)
    console.log("traer info del ", selectedOption);
    refreshParameters();
  }, [selectedOption]);

  useEffect( () => {
    async function fetchRegister() {
      let fetchRegister =  getRegisterById(register?._id, auth?.token);
      return fetchRegister;
    }
    /* Añadir al form*/
    setFetchingRegister(true);
    let tempData = {...data}
    if (register && register._id){
      fetchRegister().then( register => {
        setFetchingRegister(false);
        setData(register.data);
        let tempData = {...register.data}
        // Transformar a fecha donde datatype sea 'date'
        for (const key in tempData) {
          if (tempData[key].datatype === "date") {
            tempData[key].value = new Date(tempData[key].value); // Convertir a objeto Date
          }
        }
        setInitialRegister(tempData);
      }).catch( error => {
        setFetchingRegister(false);
        if (error.response.status === 404){
          addToast("Registro no encontrado",3);
          toggleModal();
        }
        else if (error.response.status === 500){
          addToast("Error en el servidor, intente nuevamente",2)
          toggleModal();
        }
        else if (error.response.status === 419){
          addToast("Sesión expirada",1)
          navigate('/logout')
        }
        else{
          addToast("Ha ocurrido un error inesperado",2)
          navigate('/logout')
        }
      })
    }
    else {
      config.map(parameter => 
        tempData[parameter.key] = {value: parameter.datatype === "date" ? new Date() : "", datatype: parameter.datatype, units: ""}
      )
      setFetchingRegister(false);
      setData(tempData) 
    }
    
     
  }, [config])
  return (
    <div className='flex flex-col gap-2 h-[35rem] relative overflow-auto'>
      { (fetchingParameters || fetchingRegister) &&  (
        <div className="flex justify-center h-full items-center">
          <Spinner /> 
        </div>
      )}
      {  !fetchingParameters && !fetchingRegister &&(
        <>
          <div className=' flex flex-col flex-grow gap-2'>
            {updating && (
              <div className="flex relative h-10 bg-gray-200 w-full justify-center items-center transition-max-height duration-150 ease-in-out">
                <div className="absolute">
                  <SpinnerText text="Guardando..." />
                </div>
              </div>
            )}
            { config.length === 0 && (
              <div className="flex relative h-10 bg-yellow-200 w-full justify-center items-center transition-max-height duration-150 ease-in-out">
                <div className="absolute">
                  <span className="text-gray-600 font-semibold">
                  Debe crear parámetros en la sección de configuración antes de usar esta opción
                  </span>
                </div>
              </div>
            )}
            <div className='w-full flex flex-col'>
              {config.map( element => (
                <>
                  { element.datatype === "date" && (
                    <DatePicker
                      text={element.alias || element.key}
                      onDateChange={ (date) => handleModifyForm(
                        {
                          field: element.key,
                          target: "value",
                          changeTo: date
                        }
                      )}
                      value={initialRegister? initialRegister[element?.key]?.value : new Date()}
                      error={ formError.field === element.key ? formError.error : ""}
                    />
                  )}
                  { element.datatype === "numeric" && (
                    <div className='w-full flex gap-5'>
                      <div className='basis-1/4'>
                        <InputForm
                          text={element.alias || element.key} 
                          type={"number"}
                          onChange={(event) => handleModifyForm(
                            {
                              field: element.key,
                              target: "value", 
                              changeTo: event.target.value !== "" ? Number(event.target.value) : ""
                            }
                          )}
                          value={initialRegister? initialRegister[element?.key]?.value : undefined}
                          error={ formError.field === element.key ? formError.error : ""}
                        />
                      </div>
                      <div className='basis-1/4'>
                        <InputForm 
                          text={"unidades"}
                          onChange={(event) => handleModifyForm(
                            {
                              field: element.key,
                              target: "units",  
                              changeTo: event.target.value
                            }
                          )}
                          value={initialRegister ? initialRegister[element?.key]?.units: undefined}
                        />
                      </div>      
                    </div>
                  )}
                  { element.datatype === "dictarray" && (
                    <InputList 
                      text={element.alias || element.key}
                      onChange={ (list) => handleModifyForm (
                        {
                          field: element.key,
                          target: "value",  
                          changeTo: list
                        }
                      )}
                      error={ formError.field === element.key ? formError.error : ""}
                      initialItems={initialRegister ? initialRegister[element.key]?.value : undefined}
                    />
                  )}
                  { element.datatype === "autoincrement" && initialRegister &&(
                    <InputForm 
                      text={element.alias || element.key}
                      value={initialRegister ? initialRegister[element.key]?.value : undefined}
                      error={ formError.field === element.key ? formError.error : ""}
                      enabled={false}
                    />
                  )}
                  { !element.datatype && (
                    <InputForm 
                      text={element.alias || element.key}
                      onChange={ (event) => handleModifyForm (
                        {
                          field: element.key,
                          target: "value",  
                          changeTo: event.target.value
                        }
                      )}
                      value={initialRegister ? initialRegister[element.key]?.value : undefined}
                      error={ formError.field === element.key ? formError.error : ""}
                    />
                  )}
                
                </>
                
              ))}
            </div>
          </div>
          {/* Span fijo al final del contenedor */}
          <div className='flex-none sticky bottom-0 bg-white p-2 shadow-lg'>
            <div className='flex w-full justify-end'>
              {!updating && config.length !== 0  &&(
                <button className='' onClick={() => handleSaveData()}>
                  <CheckIco width='50px' height='50px'/>
                </button>
              )}
            </div>
          </div>
        </>
      )}
      
          
    </div>
    
  )
}

export default AddEditRegisters